import { useContext } from 'react';
import Image from 'next/image';

import { CloseIcon } from '@/icons';
import { NextLink } from '@/atoms';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { imageTransformer } from '@/lib/graphql/transformers';
import { routes } from '@/lib/routes';

import type { TypeImageGraphQL } from '@/lib/graphql/types';
import type { FunctionComponent } from 'react';
import type { HeaderProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const HeaderMobile: FunctionComponent<HeaderProps> = ({
  setOpen,
}: HeaderProps) => {
  const propsProvider = useContext(InitPropsProvider);
  const bcOptions = propsProvider?.options?.ajustesGenerales;
  const logoGraphQL = bcOptions?.logoMobile as TypeImageGraphQL;
  const logo = imageTransformer(logoGraphQL);

  return (
    <div className="flex justify-between pb-4">
      <NextLink href={routes.index}>
        <a className="relative block h-9 w-32">
          <Image
            src={logo.src}
            alt={logo.alt}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
            draggable="false"
          />
        </a>
      </NextLink>

      <button
        type="button"
        className="text-gray-400 -m-2 inline-flex items-center justify-center rounded-md p-2"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close menu</span>
        <CloseIcon />
      </button>
    </div>
  );
};
