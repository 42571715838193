import Block from '@/molecules/primarymenu/desktop/Block';

import type { FunctionComponent } from 'react';
import type { ColumnProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const Column: FunctionComponent<ColumnProps> = ({
  column,
  parentMenuName,
}: ColumnProps) => (
  <div className="space-y-5">
    {column.bloque &&
      // TODO: Añadir tipos
      column.bloque.map((bloque: any, blockIndex: number) => (
        <Block
          block={bloque}
          key={blockIndex}
          parentMenuName={parentMenuName}
        />
      ))}
  </div>
);
