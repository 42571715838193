import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { BlockProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const Block: FunctionComponent<BlockProps> = ({
  block,
  parentMenuName,
}: BlockProps) => (
  <div className="space-y-5">
    <div>
      {block.title ? (
        <ConditionalWrapper
          condition={Boolean(block.page)}
          wrapper={(children) => (
            <NextLink href={block.page.url}>
              <a>{children}</a>
            </NextLink>
          )}
        >
          <p
            id={`${block.title}-${parentMenuName}-heading`}
            className="u-body u-body--m mb-4 font-medium normal-case text-primary-900"
          >
            {block.title}
          </p>
        </ConditionalWrapper>
      ) : null}

      <ul
        role="list"
        aria-labelledby={`${block.title}-${parentMenuName}-heading`}
        className="mb-2.5 space-y-1.5"
      >
        {
          // TODO: Añadir tipos
          block.menuPages &&
            block.menuPages.map((item: any) => (
              <li key={item.pageTitle} className="flex">
                <NextLink href={item.pageUrl ? item.pageUrl?.url : '/'}>
                  <a
                    href={item.pageUrl ? item.pageUrl?.url : '/'}
                    className="u-body u-body--xs my-0 normal-case text-primary-500 hover:text-primary-900"
                  >
                    {item.pageTitle}
                  </a>
                </NextLink>
              </li>
            ))
        }
      </ul>

      {block.lastLinkTitle && block.lastLinkTitleUrl && (
        <NextLink href={block.lastLinkTitleUrl?.url || '/'}>
          <a className="u-body u-body--s my-0 normal-case text-primary-900 hover:text-primary-500">
            {block.lastLinkTitle}
          </a>
        </NextLink>
      )}
    </div>
  </div>
);
